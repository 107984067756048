<template>
  <div class="image-wrapper">
    <div class="cursor-wrapper">
      <img
        :src="`/pics/support/${src}`"
        :alt="alt"
        :style="tempStyles"
        class="article-image"
        @click="$emit('viewer')"
      />
      <ui-icon v-if="showCursor" class="cursor-icon" :size="24" :icon="UiIconNames.Icon_CursorPlus" />
    </div>
    <span v-if="!hideLabel"> {{ alt }} </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiIconNames from "~/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "ArticleImage",
  components: {
    UiIcon,
  },
  emits: [
    'viewer',
  ],
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    showCursor: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const tempStyles: string =
      `width: ${ props.width ? props.width : 'auto' };` +
      `height: ${ props.height ? props.height : 'auto' };`;

    return {
      tempStyles,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.image-wrapper {
  display: flex;
  flex-flow: column;
  gap: 4px;
  padding: 24px 0;

  .cursor-wrapper {
    position: relative;
    height: fit-content;
    width: fit-content;
    align-self: center;

    .cursor-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .article-image {
    cursor: zoom-in;
    width: auto;
    height: auto;
    max-width: 800px;
    max-height: 400px;
  }

  span {
    color: #999999;
    font-size: 12px;
  }
}
</style>
